* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

body {
  background-color: black;
  color: white;
}

img {
  display: block;
  max-width: 100%;
}

.gradient-border {
  display: block;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;
  border: 2px solid transparent;
}

.gradient-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  padding: 0px;
  border-radius: inherit;
  background: black;
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(to bottom right, #0069ea, #00fcff);
}

.gradient-border.b2 {
  z-index: 2;
  border-radius: 5px;
}

.gradient-border.b2::before {
  background: linear-gradient(to top right, #0069ea, #00fcff);
}

img.logo {
  max-width: 85%;
  margin: auto;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
}

.box > div {
  height: 500px;
  padding: 0 1.2em;
  font-size: 1.4em;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box p {
  text-align: center;
  font-size: 1.2em;
  letter-spacing: -0.25px;
  margin: 3em 0 0.75em 0;
}

.box input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 1em;
  color: #ddd;
  padding: 0.5em 0.7em 0.45em 0.7em;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.box input:focus {
  outline: none;
}

.box button {
  display: block;
  margin-top: 2em;
  border: none;
  color: white;
  text-shadow: 2px 2px 3px #333;
  font-size: 0.8em;
  padding: 0.5em 2.5em;
  transform-origin: bottom left;
  transform: skew(-30deg);
  background-image: linear-gradient(to bottom right, #0069ea, #00fcff);
  border-radius: 5px;
}

.box button p {
  margin: 0;
  transform: skew(30deg);
}

.lds-ring {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 2em auto 0 auto;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 0.4s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;

}

.lds-ring div:nth-child(1) {
  animation-delay: -0.12s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.08s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.04s;
}

.lds-ring div:nth-child(4) {
  animation-delay: -0s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.result-server p {
  text-align: start;
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: #aaa;
  text-transform: uppercase;
  font-size: 0.9em;
}

.bar {
  height: 30px;
  width: 100%;
}

.bar.gradient-border {
  border-radius: 5px;
}

.bar.gradient-border::before {
  background-image: linear-gradient(to bottom right, red, yellow);
}

.bar-fill {
  transition: clip-path 0.4s ease-in-out;
  background-image: linear-gradient(to bottom right, red, yellow);
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.box p.resultsTitle {
  margin: 0 0 1.2em 0;
}

.box .view-results .results-buttons {
  display: flex;
  margin-top: 2.4em;
}

.box .view-results .results-buttons button {
  margin-top: 0;
  padding: 0.3em 1.5em;
}

.box .view-results .results-buttons button:nth-of-type(1) {
  margin-right: 0.5em;
}

.view-login {
  margin-bottom: 1em;
}

button {
  cursor: pointer;
}

span.whiteSpan {
  color: white;
}

p.progress-indicator {
  margin: 0.5em 0 0 0;
}

/* .bar.gradient-border.loading::before {
  background-image: linear-gradient(to bottom right, #0069ea, #00fcff);
}

.bar.gradient-border.loading .bar-fill {
  background-image: linear-gradient(to bottom right, #0069ea, #00fcff);
} */

@media screen and (max-width: 500px) {
  .box {
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    margin: calc((100vw - 360px) / 2 * 1.5) 0 0 0;
  }  
}  

@media screen and (max-width: 360px) {
  .box {
    transform: translateX(0%);
    top: 0;
    left: 0%;
    margin: 0;
    width: 100vw;
    overflow: hidden;
  }  
}  
